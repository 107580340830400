import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useFetch } from '../../../hooks/useFetch';
import { formatAsCurrency } from '../../../util';
import { formatDateRange } from '../../../util/date';
import SEO from '../../Seo';
import Button from '../../common/Button';
import Card from '../../common/Card';
import ModalWrapper from '../../common/ModalWrapper';
import { QueryReturnState } from '../Enum';
import { EventPaymentStatus } from '../EventApplication/ApplicationPaymentStatus';
import EventApplicationSections from '../EventApplication/EventApplicationSections';
import { IGrade, IPosition } from '../NewApiTypes';
import NewStaffMember, { NewStaffMemberForm } from '../NewStaffMember';
import PurchaseOrdersInstitute, {
  PurchaseOrdersInstituteForm
} from '../PurchaseOrdersInstitute';
import {
  applyCouponToEventRegistrationRequest,
  createUserOrganizationInvitation,
  eventRegistrationPurchaseCreditCard,
  getEventRegistrationRequest,
  getEventRegistrationRequestUsers,
  getGrades,
  getOrganizations,
  getPositions,
  submitEventRegistrationRequestAttendees,
  updateEventRegistrationRequestSeats,
  uploadEventRegistrationRequestPurchaseOrder
} from '../api';
import UserSelect from './../UserSelect';

const getEventDates = (event: any) => {
  if (!event?.isRangeDate) {
    return formatDateRange(event?.dates);
  }
  if (event?.startDate !== event?.endDate) {
    return formatDateRange([event?.startDate, event?.endDate]);
  }
  return formatDateRange([event?.startDate]);
};

const getEventTimes = (event: any) => {
  return '';
};

const isPaid = (eventRegistrationRequest: any) => {
  return [
    EventPaymentStatus.INVOICED,
    EventPaymentStatus.PAID,
    EventPaymentStatus.COMPLIMENTARY
  ].includes(eventRegistrationRequest?.payment?.status);
};

const isPaymentCompleteOrUnderway = (eventRegistrationRequest: any) => {
  return [
    EventPaymentStatus.PAID,
    EventPaymentStatus.COMPLIMENTARY,
    EventPaymentStatus.PO_SUBMITTED
  ].includes(eventRegistrationRequest?.payment?.status);
};

const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const statusText = (status: EventPaymentStatus) => {
  let text;
  switch (status) {
    case EventPaymentStatus.PAID:
    case EventPaymentStatus.COMPLIMENTARY:
      text = 'Paid';
      break;
    case EventPaymentStatus.AWAITING_PO:
      text = 'Awaiting Puchase Order';
      break;
    case EventPaymentStatus.PENDING:
      text = 'Payment Incomplete';
      break;
    case EventPaymentStatus.PO_SUBMITTED:
      text = 'Purchase order submitted';
      break;
    case EventPaymentStatus.AWAITING_INVOICE:
      text = 'Invoice pending';
      break;
    case EventPaymentStatus.INVOICED:
      text = 'Invoice sent';
      break;
    default:
      text = 'Not Paid';
  }
  return text;
};

const EventRegistrationRequest = (props: { requestId: string }) => {
  const [isEditSeats, setIsEditSeats] = React.useState(false);
  const [inputSeats, setInputSeats] = React.useState(0);
  const [isOpenPOModal, setIsOpenPOModal] = React.useState(false);
  // const [isOpenAddAttendeeModal, setIsOpenAddAttendeeModal] = React.useState(
  //   false
  // );
  const [selectedUsers, setSelectedUsers] = React.useState<any[]>([]);
  const [isOpenSectionsModal, setIsOpenSectionsModal] = React.useState(false);
  const [userForSectionModal, setUserForSectionModal] = React.useState<any>();
  const [emailValues, setEmailValues] = React.useState<string[]>([]);
  const [
    loadingCreditCardPayment,
    setLoadingCreditCardPayment
  ] = React.useState(false);
  const [couponApplied, setCouponApplied] = React.useState(false);
  const [appliedCode, setAppliedCode] = React.useState<string | null>(null);
  const [isLoadingCouponCheck, setIsLoadingCouponCheck] = React.useState(false);
  // const { data: gradesData } = useQuery<{
  //   state: QueryReturnState;
  //   grades?: IGrade[];
  // }>('grades', getGrades);
  // const { data: positionsData } = useQuery<{
  //   state: QueryReturnState;
  //   positions?: IPosition[];
  // }>('positions', getPositions);
  // const {
  //   data: organizationsData,
  //   isLoading: isLoadingOrganizations
  // } = useQuery<{
  //   state: QueryReturnState;
  //   organizations?: any[];
  //   // @ts-ignore
  // }>('get-organizations', getOrganizations);
  // const {
  //   data: usersSelectData,
  //   isLoading: isLoadingUsers,
  //   refetch: refetchUsersData
  // } = useQuery('users-select', getEventRegistrationRequestUsers);
  const { isFetching, error, data, refetch } = useQuery<
    { eventRegistrationRequest: any; price: number },
    Error
  >(['getEventRegistrationRequest', props.requestId], async () => {
    const response = await getEventRegistrationRequest(props.requestId);
    if (response?.state !== 'SUCCESS') {
      throw new Error(response?.message || 'Something went wrong');
    }
    if (response?.eventRegistrationRequest?.couponUse?.coupon) {
      setCouponApplied(true);
      setAppliedCode(
        response?.eventRegistrationRequest?.couponUse?.coupon?.code
      );
    }
    return response;
  });

  const { isLoading: isLoadingSeats, mutateAsync: updateSeats } = useMutation(
    async (seats: number) => {
      const response = await updateEventRegistrationRequestSeats(
        props.requestId,
        seats
      );
      return response;
    }
  );

  const { isLoading: isLoadingPO, mutateAsync: uploadPO } = useMutation(
    async (data: any) => {
      const response = await uploadEventRegistrationRequestPurchaseOrder(data);
      return response;
    }
  );

  const { isLoading: isLoadingSubmit, mutateAsync: handleSubmit } = useMutation(
    async (data: any) => {
      const response = await submitEventRegistrationRequestAttendees(
        props.requestId,
        data
      );
      return response;
    }
  );

  const applyCoupon = async (code: string) => {
    try {
      if (!code) {
        return;
      }
      setIsLoadingCouponCheck(true);
      const res = await applyCouponToEventRegistrationRequest({
        code,
        eventRegistrationRequestId: props.requestId
      });
      console.log('res', res);
      if (res?.state === 'SUCCESS') {
        setCouponApplied(true);
        setAppliedCode(res.coupon);
        toast.success('Coupon code applied successfully');
      } else {
        toast.error(res?.message || 'Error applying coupon code');
      }
    } catch (e) {
      toast.error('Error applying coupon code');
    } finally {
      setIsLoadingCouponCheck(false);
    }
  };

  const handleAppliedCoupon = (price: number) => {
    const couponDetails = data?.eventRegistrationRequest.couponUse?.coupon;
    if (couponDetails?.type === 'PERCENTAGE') {
      const modifiedDiscountPercentage =
        couponDetails?.discountPercentage / 100;
      return price - price * (modifiedDiscountPercentage || 0);
    } else if (couponDetails?.type === 'FIXED') {
      const discountAmount = couponDetails?.discountAmount;
      return price - (discountAmount / 100 || 0);
    } else {
      return price;
    }
  };

  useEffect(() => {
    if (data?.eventRegistrationRequest) {
      setInputSeats(data?.eventRegistrationRequest?.seatsRequested);
      const users = data?.eventRegistrationRequest?.eventApplications?.map(
        (ev: any) => ({
          id: ev.user?.id,
          // value: ev.user?.id,
          name: `${ev.user?.firstName} ${ev.user?.lastName}`,
          email: ev.user?.email,
          requestedSections: ev.requestedSections.map((rs: any) => ({
            id: rs.eventSectionId,
            ranking: rs.ranking,
            groupingId: rs.eventSections?.eventPathGroupingId,
            title: rs.eventSections?.displayTitle
          })),
          requestedGroupings: ev.requestedGroupings.map((rg: any) => ({
            id: rg.eventPathGroupingId,
            title: rg.eventPathGroupings?.title
          }))
        })
      );
      setSelectedUsers(users || []);
    }
  }, [data]);

  useEffect(() => {
    const newSelectedUsers = Array.from({
      length: inputSeats
    }).map(() => null);
    for (const [index, user] of selectedUsers.entries()) {
      newSelectedUsers[index] = user || null;
    }
    setEmailValues(Array.from({ length: inputSeats }).map(() => ''));
  }, [inputSeats]);

  const submitNewSeats = async () => {
    try {
      const response = await updateSeats(inputSeats);
      if (response?.state !== 'SUCCESS') {
        throw new Error(response?.message || 'Something went wrong');
      }
      await refetch();
      setIsEditSeats(false);
    } catch (e) {
      toast.error((e as any)?.message || 'Something went wrong');
    }
  };

  // const createUserOrganizationInvitationFetch = useFetch(
  //   createUserOrganizationInvitation
  // );

  // const submitAddNewStaffMember = async (_values: NewStaffMemberForm) => {
  //   const { organization, ...values } = _values;
  //   const {
  //     data: createdUser,
  //     state,
  //     error
  //   } = await createUserOrganizationInvitationFetch.apply({
  //     ...values,
  //     dateOfBirth: values.dateOfBirth
  //       ? moment(values.dateOfBirth, 'MM/DD/YYYY').toISOString()
  //       : null,
  //     organizationId: organization?.value,
  //     ssnLastFour: Number(values.ssnLastFour)
  //   });

  //   if (state === 'SUCCESS') {
  //     await refetchUsersData();

  //     toast.success(
  //       `Success! ${createdUser?.user?.firstName} ${createdUser?.user?.lastName} (${createdUser?.user?.email}) has now been invited to selected school.`
  //     );

  //     setIsOpenAddAttendeeModal(false);
  //   } else {
  //     toast.error(error || createdUser?.message || 'Something went wrong');
  //   }
  // };

  // const isNoOrganizations =
  //   (organizationsData as any)?.organizations?.length === 0 &&
  //   !isLoadingOrganizations;

  // const usersOptions =
  //   usersSelectData?.users?.map((user: any) => ({
  //     value: user.id,
  //     label: `${user.firstName} ${user.lastName}`,
  //     email: user.email,
  //     isPending: user.isPending
  //   })) || [];

  const price = data?.price || 0;

  const statusDescriptionClasses = (evRegReq: any) =>
    classNames({
      'sans-serif': true,
      'text-yellow-600': !isPaid(evRegReq),
      'text-green-600': isPaid(evRegReq)
    });

  const onPOSubmit = async (
    values: PurchaseOrdersInstituteForm & { type: any }
  ) => {
    const { applicants: _a, type: _t, institute: _i, ..._payload } = values;

    const payload = {
      ..._payload,
      eventRegistrationRequestId: props.requestId
    };

    try {
      const response = await uploadPO(payload);
      if (response?.state !== 'SUCCESS') {
        throw new Error(response?.message || 'Something went wrong');
      }
      await refetch();
      setIsOpenPOModal(false);
    } catch (e) {
      toast.error((e as any)?.message || 'Something went wrong');
    }
  };

  const onSubmit = async () => {
    try {
      if (
        selectedUsers.filter(Boolean)?.length !==
        data?.eventRegistrationRequest?.seatsRequested
      ) {
        throw new Error('Please fill all the seats');
      }
      const payload = {
        users: selectedUsers.map(u => u.email),
        userSections: selectedUsers.map(u => ({
          id: u.id,
          email: u.email,
          requestedSections: u.requestedSections,
          requestedGroupings: u.requestedGroupings
        }))
      };
      const response = await handleSubmit(payload);
      if (response?.state !== 'SUCCESS') {
        throw new Error(response?.message || 'Something went wrong');
      }
      await refetch();
      toast.success('Successfully saved');
    } catch (e) {
      toast.error((e as any)?.message || 'Something went wrong');
    }
  };

  const proceedCreditCardPayment = async () => {
    setLoadingCreditCardPayment(true);
    try {
      const result = await eventRegistrationPurchaseCreditCard({
        id: props.requestId
      });

      setLoadingCreditCardPayment(false);
      if (result.state === 'SUCCESS') {
        if (result.url) {
          window.location.href = result.url;
        }
      } else {
        return toast.error(result.message || 'Something went wrong');
      }
    } catch (err) {
      toast.error('Something went wrong');
      setLoadingCreditCardPayment(false);
    }
  };

  const openSectionModal = (idx: number) => {
    setIsOpenSectionsModal(true);
    setUserForSectionModal(selectedUsers[idx]);
  };

  return (
    <>
      <SEO title="Event Registration Request" />
      {/* {isOpenAddAttendeeModal && (
        <NewStaffMember
          positions={positionsData?.positions || []}
          grades={gradesData?.grades || []}
          onCancel={() => setIsOpenAddAttendeeModal(false)}
          onSubmit={userData => submitAddNewStaffMember(userData)}
          isSubmitting={false}
          isForSimplifiedRegistration
        />
      )} */}
      <ModalWrapper
        isOpen={isOpenSectionsModal && !!userForSectionModal?.id}
        onClose={() => setIsOpenSectionsModal(false)}
      >
        <EventApplicationSections
          isForSimplifiedFlow
          userId={userForSectionModal?.id}
          eventRegistrationRequestId={props.requestId}
          requestedSections={
            userForSectionModal?.requestedSections || undefined
          }
          requestedGroupings={
            userForSectionModal?.requestedGroupings || undefined
          }
          onSubmit={data => {
            const newSelectedUsers = [...selectedUsers];
            const idx = selectedUsers.findIndex(
              user => user?.id === userForSectionModal?.id
            );
            newSelectedUsers[idx] = {
              ...newSelectedUsers[idx],
              ...data
            };
            setSelectedUsers(newSelectedUsers);
            setIsOpenSectionsModal(false);
          }}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={isOpenPOModal && !!data?.eventRegistrationRequest?.event?.id}
        onClose={() => setIsOpenPOModal(false)}
      >
        <PurchaseOrdersInstitute
          isForSimplifiedRegistration
          eventId={data?.eventRegistrationRequest?.event?.id}
          onCancel={() => setIsOpenPOModal(false)}
          onSubmit={onPOSubmit}
          isSubmitLoading={isLoadingPO}
        />
      </ModalWrapper>
      <Card>
        <h3 className="text-primary font-bold text-2xl mb-2">
          Event Registration
        </h3>
        {isFetching && (
          // || isLoadingOrganizations
          <p>Loading...</p>
        )}
        {error && <p>Error: {error?.message}</p>}
        {data && (
          <>
            <h1 className="text-gray-700 text-xl font-semibold">
              {data.eventRegistrationRequest?.event?.name}
            </h1>
            <div className="text-gray-600">
              <p className="sans-serif">
                {getEventDates(data.eventRegistrationRequest?.event)}
              </p>
              <p className="sans-serif">
                {getEventTimes(data.eventRegistrationRequest?.event)}
              </p>
            </div>
            <div className="mt-6 bg-yellow-100 text-yellow-700 border border-yellow-300 rounded-lg p-4">
              <section className="w-2/3">
                <p>
                  Thank you for registering for the{' '}
                  <b>{data.eventRegistrationRequest?.event?.name}</b>. Here you
                  will find information about your registration and tools for
                  managing your payment and attendees.
                </p>
                <p className="mt-2">
                  If you have selected a single seat for purchase, the seat has
                  automatically been assigned to your account. If you have
                  selected multiple seats, you will need to assign the seats to
                  your attendees in the "Event Attendees" section below.
                </p>
              </section>
            </div>
            <div className="mt-6">
              <h2 className="text-primary font-bold text-xl mb-2">
                Registration Details
              </h2>
              <div className="grid grid-cols-3 gap-4 mt-2">
                <div>
                  <h3 className="text-gray-700 font-semibold">Attendees</h3>
                  {isLoadingSeats ? (
                    <>Loading...</>
                  ) : (
                    <>
                      {!isPaymentCompleteOrUnderway(
                        data?.eventRegistrationRequest
                      ) && isEditSeats ? (
                        <></>
                      ) : (
                        <p className="text-gray-600">{inputSeats}</p>
                      )}
                      {!isPaymentCompleteOrUnderway(
                        data?.eventRegistrationRequest
                      ) && (
                        <div>
                          {isEditSeats ? (
                            <div className="flex gap-2">
                              <input
                                name="seats"
                                type="number"
                                max={100}
                                min={1}
                                className="border border-gray-400 rounded-md p-1 w-1/2"
                                value={inputSeats}
                                onChange={e => setInputSeats(+e.target.value)}
                                disabled={isLoadingSeats}
                              />
                              <Button
                                text="Save"
                                type="button"
                                color="main"
                                size="sm"
                                onClick={() => submitNewSeats()}
                                isLoading={isLoadingSeats}
                                icon={faCheck}
                                className="flex items-center"
                              />
                              <Button
                                text="Cancel"
                                type="button"
                                color="error"
                                onClick={() => {
                                  setIsEditSeats(false);
                                  setInputSeats(
                                    data?.eventRegistrationRequest
                                      ?.seatsRequested
                                  );
                                }}
                                size="sm"
                                disabled={isLoadingSeats}
                                icon={faTimes}
                                className="flex items-center"
                              />
                            </div>
                          ) : (
                            <button
                              type="button"
                              className="text-primary text-xs font-bold underline cursor-pointer"
                              onClick={() => setIsEditSeats(true)}
                            >
                              Modify
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <h3 className="text-gray-700 font-semibold">Total Cost</h3>
                  <p className="text-gray-600">
                    {formatAsCurrency(handleAppliedCoupon(inputSeats * price))}
                  </p>
                </div>
                <div>
                  <h3 className="text-gray-700 font-semibold">
                    Payment Status
                  </h3>
                  <div className="">
                    <p
                      className={statusDescriptionClasses(
                        data?.eventRegistrationRequest
                      )}
                    >
                      {statusText(
                        data?.eventRegistrationRequest?.payment?.status
                      )}
                    </p>
                    {!isPaymentCompleteOrUnderway(
                      data?.eventRegistrationRequest
                    ) && (
                      <>
                        <div>
                          <div className="mt-2">
                            <Button
                              text="Pay with PO"
                              size="sm"
                              onClick={() => setIsOpenPOModal(true)}
                              className="mr-2"
                            />
                            <Button
                              text="Pay with Credit Card"
                              onClick={proceedCreditCardPayment}
                              isLoading={loadingCreditCardPayment}
                              size="sm"
                            />
                          </div>

                          <div className="flex items-end gap-x-2 mt-3">
                            <input
                              className="appearance-none block rounded-md shadow-sm border border-gray-300 px-2 py-0.5"
                              placeholder="Coupon Code"
                              type="text"
                              name="couponCode"
                              value={appliedCode || ''}
                              onChange={e => setAppliedCode(e.target.value)}
                            />
                            <Button
                              type="button"
                              text="Apply"
                              size="sm"
                              isLoading={false}
                              disabled={false}
                              onClick={() => applyCoupon(appliedCode || '')}
                            />
                          </div>
                          <div className="flex flex-row">
                            <div className="mt-2">
                              {couponApplied ? (
                                <p className="text-green-600 flex items-center gap-x-1">
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="w-4 h-4"
                                  />
                                  Coupon applied
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex gap-x-4">
                <h2 className="text-primary font-bold text-xl">
                  Event Attendees
                </h2>
              </div>
              {/* <div className="text-sm font-sans text-secondary">
                Can't find the user you're looking for? Add them to your
                organization to proceed
              </div>
              <div className="mb-3 mt-1">
                <Button
                  text="Add New User"
                  size="sm"
                  type="button"
                  onClick={() => setIsOpenAddAttendeeModal(true)}
                  disabled={isNoOrganizations}
                />
              </div> */}
              {/* {isNoOrganizations && (
                <div>
                  <p className="text-red-600">
                    You must have at least one organization affiliation
                  </p>
                  <div className="mt-1 sans-serif">
                    <span
                      onClick={() => navigate('/members/profile')}
                      className="text-primary cursor-pointer sans-serif"
                    >
                      Click here{' '}
                    </span>{' '}
                    to add an organization affiliation
                  </div>
                </div>
              )} */}
              {/* {Array.from({
                length: data?.eventRegistrationRequest?.seatsRequested
              }).map((_, index) => (
                <div className="mt-4 mb-2 flex gap-4" key={index}>
                  <div className="w-1/2">
                    <p className="mb-1 text-gray-600 font-bold">
                      Seat {index + 1}
                    </p>
                    <div className="mb-3 w-full">
                      <UserSelect
                        isDisabled={isNoOrganizations}
                        isLoading={isLoadingUsers || isLoadingOrganizations}
                        // @ts-ignore
                        value={selectedUsers[index]}
                        options={usersOptions.filter((opt: any) => {
                          return !selectedUsers.some(
                            selectedUser => selectedUser?.id === opt.value
                          );
                        })}
                        onChange={(value: any) => {
                          if (
                            !selectedUsers.some(
                              user => user?.id === value.value
                            )
                          ) {
                            const newSelectedUsers = [...selectedUsers];
                            newSelectedUsers[index] = {
                              ...value,
                              id: value.value
                            };
                            setSelectedUsers(newSelectedUsers);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-3">
                      <p className="mb-1 text-gray-600 font-bold">
                        Desired Section
                      </p>
                      <div className="-mt-1">
                        <Button
                          text="Manage"
                          size="sm"
                          disabled={!selectedUsers[index]}
                          onClick={() => {
                            openSectionModal(index);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      {selectedUsers[index]?.requestedSections?.length ? (
                        <ol
                          className={classNames({
                            'list-inside': true,
                            'list-decimal':
                              selectedUsers[index]?.requestedSections?.length >
                              1,
                            'list-none':
                              selectedUsers[index]?.requestedSections
                                ?.length === 1
                          })}
                        >
                          {selectedUsers[index]?.requestedSections
                            ?.sort((a: any, b: any) => a.ranking - b.ranking)
                            .map((section: any) => (
                              <li key={section.id}>{section.title}</li>
                            ))}
                        </ol>
                      ) : selectedUsers[index]?.requestedGroupings?.length ? (
                        <ul className="list-none list-inside">
                          {selectedUsers[index]?.requestedGroupings.map(
                            (grouping: any) => (
                              <li key={grouping.id}>{grouping.title}</li>
                            )
                          )}
                        </ul>
                      ) : (
                        <p className="text-gray-600 italic">None</p>
                      )}
                    </div>
                  </div>
                </div>
              ))} */}
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/2"
                    >
                      Attendee
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Desired Section
                    </th>
                    <th
                      scope="col"
                      className="pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Array.from({
                    length: data?.eventRegistrationRequest?.seatsRequested
                  }).map((_, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap w-1/2">
                        {selectedUsers[index] ? (
                          <div className="flex gap-2 justify-between items-center w-full">
                            <div>
                              <p className="text-gray-600 font-semibold">
                                {selectedUsers[index]?.name}
                              </p>
                              <p className="text-gray-600">
                                {selectedUsers[index]?.email}
                              </p>
                            </div>
                            <Button
                              text="Remove"
                              color="error"
                              className="w-24 h-8"
                              size="sm"
                              type="button"
                              onClick={() => {
                                const newSelectedUsers = [...selectedUsers];
                                newSelectedUsers[index] = undefined;
                                setSelectedUsers(newSelectedUsers);
                                const newEmailValues = [...emailValues];
                                newEmailValues[index] = '';
                                setEmailValues(newEmailValues);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="flex gap-2 justify-between items-center w-full">
                            <input
                              type="email"
                              className="border-gray-200 border p-2 sans-serif rounded-md bg-gray-50 w-full max-w-xs"
                              placeholder="Email"
                              value={emailValues[index] || ''}
                              onChange={e => {
                                const newEmailValues = [...emailValues];
                                newEmailValues[index] = e.target.value;
                                setEmailValues(newEmailValues);
                              }}
                            />
                            <Button
                              text="Add +"
                              className="w-24 h-8"
                              disabled={
                                !validateEmail(emailValues[index]) ||
                                emailValues.some(
                                  (email, i) =>
                                    email === emailValues[index] && i !== index
                                )
                              }
                              size="sm"
                              type="button"
                              onClick={() => {
                                const email = emailValues[index];
                                if (
                                  !selectedUsers.some(
                                    user => user?.email === email
                                  )
                                ) {
                                  const newSelectedUsers = [...selectedUsers];
                                  newSelectedUsers[index] = {
                                    name: '',
                                    email: email,
                                    requestedSections: [],
                                    requestedGroupings: []
                                  };
                                  setSelectedUsers(newSelectedUsers);
                                }
                              }}
                            />
                          </div>
                        )}
                        {/*<UserSelect
                          isDisabled={isNoOrganizations}
                          isLoading={isLoadingUsers || isLoadingOrganizations}
                          // @ts-ignore
                          value={selectedUsers[index]}
                          options={usersOptions.filter((opt: any) => {
                            return !selectedUsers.some(
                              selectedUser => selectedUser?.id === opt.value
                            );
                          })}
                          onChange={(value: any) => {
                            if (
                              !selectedUsers.some(
                                user => user?.id === value.value
                              )
                            ) {
                              const newSelectedUsers = [...selectedUsers];
                              newSelectedUsers[index] = {
                                ...value,
                                id: value.value
                              };
                              setSelectedUsers(newSelectedUsers);
                            }
                          }}
                        />*/}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {selectedUsers[index]?.requestedSections?.length ? (
                          <ol
                            className={classNames({
                              'list-inside': true,
                              'list-decimal':
                                selectedUsers[index]?.requestedSections
                                  ?.length > 1,
                              'list-none':
                                selectedUsers[index]?.requestedSections
                                  ?.length === 1
                            })}
                          >
                            {selectedUsers[index]?.requestedSections
                              ?.sort((a: any, b: any) => a.ranking - b.ranking)
                              .map((section: any) => (
                                <li key={section.id}>{section.title}</li>
                              ))}
                          </ol>
                        ) : selectedUsers[index]?.requestedGroupings?.length ? (
                          <ul className="list-none list-inside">
                            {selectedUsers[index]?.requestedGroupings.map(
                              (grouping: any) => (
                                <li key={grouping.id}>{grouping.title}</li>
                              )
                            )}
                          </ul>
                        ) : (
                          <p className="text-gray-600 italic">None</p>
                        )}
                      </td>
                      <td className="pr-6 py-4 whitespace-nowrap text-right text-sm font-medium w-1/6">
                        <Button
                          text="Manage"
                          size="sm"
                          disabled={!selectedUsers[index]?.id}
                          onClick={() => {
                            openSectionModal(index);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
            </div>
            <div className="flex justify-end w-full mt-5">
              <Button
                text="Save"
                size="sm"
                onClick={onSubmit}
                isLoading={isLoadingSubmit}
                disabled={
                  selectedUsers.filter(Boolean)?.length !==
                  data?.eventRegistrationRequest?.seatsRequested
                  //   ||
                  // selectedUsers
                  //   .filter(Boolean)
                  //   .some(
                  //     (user: any) =>
                  //       !user?.requestedSections?.length &&
                  //       !user?.requestedGroupings?.length
                  //   )
                }
              />
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default EventRegistrationRequest;
