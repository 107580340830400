import { faClock, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import Button from '../common/Button';
import Card from '../common/Card';
import SEO from '../Seo';
import {
  getAvailablePackages,
  getSchoolYears,
  initiatePackageRequest
} from './api';
import ApplicationStatus from './ApplicationStatus';

type PackageStatus =
  | 'NOT_STARTED'
  | 'STARTED'
  | 'SUBMITTED'
  | 'ACCEPTED'
  | 'REJECTED'
  | 'CHANGES_REQUESTED'
  | 'WITHDRAWN';

const getPackageManagementLink = (
  packageRequestId: string,
  segment?: string
) => {
  return `/members/manage-services/package-request/${packageRequestId}/${segment ||
    'staff-options'}`;
};

interface PackageListingProps {
  packageId: string;
  packageName: string;
  packageDescription: string;
  packageStatus: PackageStatus;
  packageStatusId: number;
  organizationId: string;
  onInitiatePackage?: () => void;
  packageRequestId?: string;
  initiatePackageLoading?: boolean;
}

const PackageListing = (props: PackageListingProps) => {
  return (
    <div className="mt-2 p-4 border border-gray-300 rounded-lg flex justify-between">
      <div className="w-2/3">
        <p className="sans-serif font-bold text-gray-800 text-lg">
          {props.packageName}
        </p>
        {props.packageStatus === 'NOT_STARTED' ? (
          <div
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: props.packageDescription
            }}
          />
        ) : null}
        {props.packageRequestId ? (
          <ApplicationStatus status={props.packageStatus} />
        ) : null}
      </div>
      <div className="flex justify-between">
        <div className="my-auto">
          {props.packageStatus === 'NOT_STARTED' ? (
            <Button
              text="Choose Package"
              onClick={props.onInitiatePackage}
              isLoading={props.initiatePackageLoading}
            />
          ) : null}
        </div>
        <div className="my-auto">
          {['STARTED', 'CHANGES_REQUESTED', 'REOPENED'].includes(
            props.packageStatus
          ) ? (
            <Link
              to={getPackageManagementLink(props.packageRequestId as string)}
            >
              <Button text="Continue Application" />
            </Link>
          ) : null}
        </div>
        <div className="my-auto">
          {['SUBMITTED', 'REJECTED', 'WITHDRAWN'].includes(
            props.packageStatus
          ) ? (
            <Link
              to={getPackageManagementLink(
                props.packageRequestId as string,
                'review'
              )}
            >
              <Button text="Review Application" />
            </Link>
          ) : null}
          <div className="sm:flex mb-2 ">
            {props.packageStatus === 'ACCEPTED' && (
              <>
                <div className="w-full flex items-center mr-2">
                  <Link
                    to={`/members/manage-services/package-request/${props.packageRequestId}/assign-tickets`}
                  >
                    <Button text="Allocate Tickets and Assign Staff" />
                  </Link>
                </div>
                <div className="w-full flex items-center">
                  <Link
                    to={getPackageManagementLink(
                      props.packageRequestId as string,
                      'review'
                    )}
                  >
                    <Button text="Review Package" />
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function getSchoolYearsForSelect(schoolYears: any) {
  const schoolYearsOptions: Array<{
    value: string;
    label: string;
  }> = schoolYears.map((year: any) => ({
    value: year.id,
    label: year.name
  }));

  return schoolYearsOptions.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
}

function getMostRecentSchoolYear(schoolYears: any) {
  const formattedSchoolYears = getSchoolYearsForSelect(schoolYears);

  return formattedSchoolYears[formattedSchoolYears.length - 1];
}

interface SelectServiceProps {
  organizationId: string;
}
const SelectService = (props: SelectServiceProps) => {
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState('');
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    props.organizationId
  );

  useEffect(() => {
    setSelectedOrganizationId(props.organizationId);
  }, [props.organizationId]);

  const {
    isLoading: schoolYearsLoading,
    error: schoolYearsError,
    data: schoolYearsResult
  } = useQuery('schoolYears', getSchoolYears);

  const {
    isLoading: availablePackagesLoading,
    error: availablePackagesError,
    data: availablePackagesResult
  } = useQuery(
    ['availablePackages', selectedSchoolYearId, selectedOrganizationId],
    () => {
      if (selectedSchoolYearId) {
        return getAvailablePackages(
          selectedSchoolYearId,
          selectedOrganizationId
        );
      }
    }
  );

  console.log('the result', availablePackagesResult);

  useEffect(() => {
    if (schoolYearsResult && schoolYearsResult.schoolYears) {
      setSelectedSchoolYearId(
        getMostRecentSchoolYear(schoolYearsResult.schoolYears).value.toString()
      );
    }
  }, [schoolYearsResult]);

  const initiatePackageMutation = useMutation(
    ['iniiatePackage'],
    initiatePackageRequest,
    {
      onSuccess: ({ packageRequest }) =>
        navigate(getPackageManagementLink(packageRequest.id as string))
    }
  );

  return (
    <>
      <SEO title="Select a Service" />
      <Card>
        <h2 className="sans-serif text-primary font-bold text-2xl">
          Select a Service
        </h2>
        <p className="sans-serif mt-4">
          "Please select a package below based on the location of your school.
          The travel expenses are estimates and you will only be billed for
          actual costs. Please contact{' '}
          <a href="mailto:kathy@readingwriting.net">
            Kathy Neville (kathy@readingwriting.net)
          </a>
          with any questions or special requests.
        </p>
        {schoolYearsLoading && <p>Loading...</p>}
        {schoolYearsResult && schoolYearsResult.schoolYears && (
          <>
            <div className="mt-6">
              <h3 className="text-gray-600 font-semibold text-lg">
                School Year
              </h3>
              <div className="w-1/3 mt-2">
                <Select
                  defaultValue={getMostRecentSchoolYear(
                    schoolYearsResult.schoolYears
                  )}
                  options={getSchoolYearsForSelect(
                    schoolYearsResult.schoolYears
                  )}
                  onChange={option => {
                    if (option?.value) {
                      setSelectedSchoolYearId(option.value.toString());
                    }
                  }}
                />
              </div>
            </div>

            <div className="mt-6">
              {availablePackagesResult &&
              availablePackagesResult.unstartedPackages &&
              !!availablePackagesResult.unstartedPackages.length ? (
                <>
                  <h3 className="text-gray-600 font-semibold text-lg">
                    Available Services
                  </h3>
                  <>
                    {availablePackagesResult.unstartedPackages.map(p => (
                      <PackageListing
                        packageId={p.id}
                        organizationId={selectedOrganizationId}
                        packageName={p.name}
                        packageDescription={p.description}
                        packageStatus={p.status}
                        packageStatusId={123}
                        onInitiatePackage={() =>
                          initiatePackageMutation.mutate({
                            packageId: p.id,
                            organizationId: selectedOrganizationId
                          })
                        }
                        initiatePackageLoading={
                          initiatePackageMutation.isLoading
                        }
                        key={p.id}
                      />
                    ))}
                  </>
                </>
              ) : null}
            </div>
            <div className="mt-6">
              {availablePackagesResult &&
              availablePackagesResult.packageRequests &&
              !!availablePackagesResult.packageRequests.length ? (
                <>
                  <h3 className="text-gray-600 font-semibold text-lg">
                    Your Service Applications
                  </h3>
                  <>
                    {availablePackagesResult.packageRequests.map(p => (
                      <PackageListing
                        packageId={p.id}
                        organizationId={selectedOrganizationId}
                        packageName={p.package.name}
                        packageDescription={p.package.description}
                        packageStatus={p.status}
                        packageStatusId={123}
                        key={p.id}
                        packageRequestId={p.id}
                      />
                    ))}
                  </>
                </>
              ) : null}
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default SelectService;
