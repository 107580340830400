import React, { useContext, useState } from 'react';
import { useMutation, useQueries, useQuery } from 'react-query';
import { AuthContext } from '../../context/AuthContext';
import Button from '../common/Button';
import Card from '../common/Card';
import {
  getFilteredUsers,
  getCurrentOrganizationUsers,
  sendCtleNotifications
} from './Queries';
import { useLocation } from '@reach/router';
import { parseOrganizationUsers } from './UserUtil';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function shouldShowNotice(organizationId: string, organizations: any[]) {
  var showNotice = false;
  if (organizationId != null && organizations != null && organizations.length) {
    for (var i = 0; i < organizations.length; i++) {
      var org = organizations[i];
      if (org.organization_id == organizationId) {
        if (org.organization_state && org.organization_state.trim().length) {
          var formattedStateName = org.organization_state.trim().toLowerCase();
          showNotice =
            formattedStateName === 'ny' ||
            formattedStateName === 'new york' ||
            formattedStateName === 'n y';
        }
      }
    }
  }
  return true;
}

interface CtleNoticeProps {
  organizationId: string;
}
const CtleNotice = (props: CtleNoticeProps) => {
  const auth = useContext(AuthContext);
  const [parsedUsers, setParsedUsers] = useState();
  const [ctleMembers, setCtleMembers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  // const results = useQueries([
  //   {
  //     queryKey: ['organizationUsers', 1],
  //     queryFn: () => getCurrentOrganizationUsers(),
  //     onSuccess: data => setParsedUsers(parseOrganizationUsers(data))
  //   },
  //   {
  //     queryKey: ['filteredUsers', 2],
  //     queryFn: () => getFilteredUsers(props.organizationId),
  //     onSuccess: data => setCtleMembers(data.Data)
  //   }
  // ]);

  // const mutation = useMutation(data => sendCtleNotifications(data), {
  //   onSuccess: data => {
  //     setCtleMembers([]);
  //     toast.success('Notifications sent');
  //   },
  //   onError: err => {
  //     toast.error('Something went wrong');
  //   }
  // });

  return (
    <>
      {parsedUsers && shouldShowNotice(props.organizationId, parsedUsers) ? (
        <Card>
          <h2 className="mb-4 text-2xl text-gray-700 font-bold">Notice</h2>
          <p className="text-gray-700 sans-serif mb-2">
            If you are a principal in a New York State or City school and your
            teachers would like to receive Continuing Teacher and Leadership
          </p>
          {!showDetails && (
            <div className="mt-2">
              <button
                className="focus:outline-none text-primary underline hover:text-primary-dark sans-serif"
                onClick={() => setShowDetails(true)}
              >
                Show Details{' '}
                <span className="ml-1 text-xs">
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </button>
            </div>
          )}
          {showDetails && (
            <div>
              <ul className="mb-2 ml-8">
                {ctleMembers.map((user: any, i: number) => (
                  <li className="text-gray-700 sans-serif list-disc" key={i}>
                    {user.FullName}
                  </li>
                ))}
              </ul>
              <p className="text-gray-700 sans-serif mb-2">
                They will need to provide a date of birth and the last four
                digits of their social security number. You can send them a
                profile update notification by clicking the button below.
              </p>
              <p className="text-gray-700 sans-serif mb-2">
                If you are not a school in New York State, please ignore this
                message.
              </p>
              {showDetails && (
                <div className="mt-2">
                  <button
                    className="focus:outline-none text-primary underline hover:text-primary-dark sans-serif"
                    onClick={() => setShowDetails(false)}
                  >
                    Close Details{' '}
                    <span className="ml-1 text-xs">
                      <FontAwesomeIcon icon={faChevronUp} />
                    </span>
                  </button>
                </div>
              )}
              <div className="mt-4">
                <Button
                  text="Send Update Notifications"
                  isLoading={false}
                  onClick={
                    () => {
                      console.log('Send Update Notifications');
                    }
                    // mutation.mutate({
                    //   CurrentPage: 1,
                    //   ItemsPerPage: 100000,
                    //   CheckCtleRequirements: true,
                    //   OrganizationID: props.organizationId
                    // })
                  }
                />
              </div>
            </div>
          )}
        </Card>
      ) : null}
    </>
  );
};

export default CtleNotice;
